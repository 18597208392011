<template>
  <div class="flightbooking_form_rightbody">
    <div class="heading">
      <h3>
        <div class="d-flex align-items-center">
          <span>
            <img src="/assets/sabre-img/case.png" alt="case"/>
          </span>
          <strong>{{$t("sabre.booking.baggage-box.baggage")}}</strong>
        </div> {{$t("sabre.booking.baggage-box.baggage-notice")}}<small>${{averagePrice}}</small>
      </h3>
    </div>
    <div class="flightbooking_form_middel">

      <baggage-one-item :index="inx" v-for="(person, inx) in passengers" :key="inx" :personData="person" :baggageInfoData="bagChargeInfo" @updateData="update"/>

    </div>
    <div class="flightbooking_form_footer">
      <p>{{totalCount}} {{$t("sabre.booking.baggage-box.suitcases")}}* {{averagePrice}}$ {{$t("sabre.booking.baggage-box.overall-supplement")}} <strong>${{totalPrice}} </strong>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'baggage-form',
  props: {
    sltTicket: {
      type: Number,
      default: -1,
    },
  },
  components: {
    BaggageOneItem: () => import('./baggageOneItem'),
  },
  computed: {
    ...mapGetters({
      orderedFlight: 'GET_SABRE_ORDERED_FLIGHT',
      passengers: 'GET_SABRE_PASSENGER_DATA',
      bagChargeDesc: 'GET_SABRE_BOOKING_BAGGAGE_DESC',
    }),
    bagChargeInfo() {
      const { sltTicket, orderedFlight } = this;
      if (sltTicket === -1) return [];
      return orderedFlight?.pricingInformation[sltTicket]?.fare?.passengerInfoList[0]?.baggageChargeInformation || [];
    },
  },
  data: () => ({
    baggageChargeData: [],
    totalPrice: 0,
    totalCount: 0,
    averagePrice: 0,
  }),
  methods: {
    update(data) {
      const { passengerRefId } = data;
      this.baggageChargeData[passengerRefId] = data;
      this.totalPrice = (this.baggageChargeData.length) ? this.baggageChargeData.reduce((total, num) => total + (num?.totalPrice || 0), 0) : 0;
      this.totalCount = (this.baggageChargeData.length) ? this.baggageChargeData.reduce((total, num) => total + (num?.outCount || 0) + (num?.returnCount || 0), 0) : 0;
      this.averagePrice = (this.totalCount === 0) ? 0 : Math.round(this.totalPrice / this.totalCount);
      this.$emit('update', { type: 'baggage', data: { totalPrice: this.totalPrice, data: this.baggageChargeData } });
    },
  },
};
</script>
